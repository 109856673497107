import { MainPage, PageHead, SeoFooter } from '@/components'
import withCity from '@/lib/withCity'

const Home = ({ data, articles }) => {
    console.log(data.seo_meta.addtional_description);
    return (
        <>
            <PageHead meta={data.seo_meta} />
            <MainPage data={data} articles={articles} />
            {data.seo_meta.addtional_description && <SeoFooter text={data.seo_meta.addtional_description} />}
        </>
    )
}

Home.getInitialProps = withCity(async ({ api, cities }) => {
    const { data } = await api.pageWithSlug('index')({
        city: cities.find((item) => item.is_default).slug,
    })
    data.reviews = await api.reviews() ?? []
    const articles = await api.articles.main()

    return { data: data, articles: articles.data, footer: 'large' }
})

export default Home

import Router from 'next/router'

export default function withAuth(f) {
    return (args) => {
        const { res, city } = args

        if (city && !city.is_default) {
            if (res) {
                res.writeHead(302, {
                    Location: `/${city.slug}`,
                })
                res.end()
            } else {
                Router.push('/[slug]', `/${city.slug}`)
            }
            return {}
        } else {
            return f(args)
        }
    }
}
